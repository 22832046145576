import React from 'react'

import PropTypes from 'prop-types'

import './banner1.css'

const Banner1 = (props) => {
  return (
    <div
      className={`banner1-container thq-section-padding ${props.rootClassName} `}
    >
      <div className="banner1-max-width thq-section-max-width">
        <div className="banner1-container1"></div>
      </div>
    </div>
  )
}

Banner1.defaultProps = {
  content1:
    'Immerse yourself in a collection of short stories that capture the essence of diverse cultures and emotions from around the globe.',
  action1: 'Start Reading Now',
  heading1: 'Explore the World Through Stories',
  rootClassName: '',
}

Banner1.propTypes = {
  content1: PropTypes.string,
  action1: PropTypes.string,
  heading1: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Banner1
