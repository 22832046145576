import React from 'react'

import PropTypes from 'prop-types'

import './primary-button.css'

const PrimaryButton = (props) => {
  return (
    <div className="primary-button-container">
      <a
        href="https://play.google.com/store/apps/details?id=com.storyline.storytelling"
        target="_blank"
        rel="noreferrer noopener"
        className="primary-button-link MediumLabel button"
      >
        {props.button}
      </a>
    </div>
  )
}

PrimaryButton.defaultProps = {
  button: 'Download Now',
}

PrimaryButton.propTypes = {
  button: PropTypes.string,
}

export default PrimaryButton
