import React from 'react'

import PropTypes from 'prop-types'

import './store-banner.css'

const StoreBanner = (props) => {
  return (
    <div className="store-banner-store-banner">
      <a
        href="https://play.google.com/store/apps/details?id=com.storyline.storytelling"
        target="_blank"
        rel="noreferrer noopener"
        className="store-banner-link"
      >
        <img
          alt={props.imageAlt1}
          src={props.imageSrc1}
          className="store-banner-image"
        />
      </a>
    </div>
  )
}

StoreBanner.defaultProps = {
  imageSrc: '6b27718d-5434-4cc9-bba3-1266a4247292',
  imageAlt1: 'image',
  imageSrc1: '/google-play-badge-200h.png',
  imageAlt: 'image',
}

StoreBanner.propTypes = {
  imageSrc: PropTypes.string,
  imageAlt1: PropTypes.string,
  imageSrc1: PropTypes.string,
  imageAlt: PropTypes.string,
}

export default StoreBanner
